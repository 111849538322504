import {
  account_id,
  auth_key,
  catalog_views,
  domain_key,
} from "./config";

import { productSearch, autoSuggest } from '@bloomreach/discovery-web-sdk';

const config = {
  account_id,
  auth_key,
  domain_key,
};
// initialize the Discovery SDK 


export const getSuggestions = (query) => {
  const uid = encodeURIComponent(`uid=12345:v=11.8:ts=${Date.now()}:hc=3`);
  const searchOptions = {
    _br_uid_2: uid,
    catalog_views: catalog_views,
    url: 'https://example.com',
    ref_url: 'https://example.com',
    request_id: '12345',
    q: query,
  };

  return autoSuggest(config, searchOptions);
};

export const getSearchResults = (query, page, perPage, sort, fq) => {
  const uid = encodeURIComponent(`uid=12345:v=11.8:ts=${Date.now()}:hc=3`);
  // call API using SDK
  const params = {
    _br_uid_2: uid,
    url: 'https://example.com',
    ref_url: 'https://example.com',
    request_id: '12345',
    'facet.version': '3.0',
    q: query,
    start: page * perPage,
    rows: perPage,
    sort: sort,
    'stats.field': 'sale_price',
    'query.numeric_precision': 'standard',
    br_diagnostic: 'all',
    fl: 'condition,pattern,countryOfOrigin,flavor,pid,rating,color,size,title,description,brand,price,price_range,sale_price,sale_price_range,url,promotions,thumb_image,skuid,sku_color,sku_size,sku_thumb_images,sku_swatch_images,sku_price,sku_sale_price,category',
    // onSale,inStock,score,is_live,
  };
  if (fq && Object.keys(fq).length > 0) {
    params.fq = Object.keys(fq).map((key) => `${key}:${fq[key]}`);
  }
  return productSearch(config, params);
};
